import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }

  return combined
}

export function isKeyInEnum<T>(
  key: string | number | symbol,
  enumType: T,
): key is keyof T {
  return enumType[key as keyof T] !== undefined
}

export function isNotNullish<T>(value: T | null | undefined): value is T {
  return !!value
}

export function mergeAndUpdateSearchParams({
  existingSearchParams,
  setSearchParams,
  newSearchParams,
}: {
  existingSearchParams: URLSearchParams
  setSearchParams: (
    params: { [key: string]: string | string[] },
    options: {
      preventScrollReset?: boolean
    },
  ) => void
  newSearchParams: { [key: string]: string | string[] }
}) {
  //for each key, get existing values and add it to an object
  const existingSearchParamsObject: {
    [key: string]: string | string[]
  } = {}

  const searchParamKeys = existingSearchParams.keys()

  for (const key of searchParamKeys) {
    const values = existingSearchParams.getAll(key)
    if (values.length > 1) {
      existingSearchParamsObject[key] = values
    } else {
      existingSearchParamsObject[key] = values[0]
    }
  }

  setSearchParams(
    {
      ...existingSearchParamsObject,
      ...newSearchParams,
    },
    { preventScrollReset: true },
  )
}
